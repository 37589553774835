// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/About.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-companies-businesses-js": () => import("./../../../src/templates/CompaniesBusinesses.js" /* webpackChunkName: "component---src-templates-companies-businesses-js" */),
  "component---src-templates-contact-us-js": () => import("./../../../src/templates/ContactUs.js" /* webpackChunkName: "component---src-templates-contact-us-js" */),
  "component---src-templates-media-js": () => import("./../../../src/templates/Media.js" /* webpackChunkName: "component---src-templates-media-js" */),
  "component---src-templates-project-references-js": () => import("./../../../src/templates/ProjectReferences.js" /* webpackChunkName: "component---src-templates-project-references-js" */)
}

